<style scoped>
#planning-container {
  background-color: white;
  box-shadow: 0 10px 15px -6px rgb(0 20 40 / 35%);
  max-height: calc(100vh - 125px);
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  margin: 30px 30px 0px 30px
}

#planning-header {
  display: flex;
  background-color: rgb(240, 243, 246);
  border-bottom: 1px solid rgb(220, 225, 230);
  text-transform: uppercase;
  color: rgb(80, 90, 100);
  height: 42px;
  font-size: 12px;
  font-weight: 500;
}

.planning-header-day {
  margin-right: 5px;
}

#planning-header > div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
}

#planning-header > div:first-child {
  flex: 0 0 180px;
  width: 180px;
}

#planning-header > div:not(:last-child) {
  border-right: 1px solid rgb(220, 225, 230);
}

#planning-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: overlay;
  margin-bottom: -2px;
  scrollbar-width: thin;
}

#planning-content::-webkit-scrollbar {
  display: none;
}

.technician-planning {
  flex: 1;
}

.technician-name {
  flex: 0 0 150px;
  width: 150px;
  box-sizing: border-box;
  background-color: rgb(250, 251, 252);
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: rgb(50, 50, 70);
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  text-align: left;
}

#planning-content .technician-planning-container .technician-name {
  border-bottom: 1px solid rgb(220, 225, 230);
}

.technician-planning-container {
  display: flex;
}

.morning-planning, .afternoon-planning {
  display: flex;
}

.morning-planning > div, .afternoon-planning > div {
  flex: 1;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 102px;
  padding: 15px;
  position: relative;
  z-index: 1;
}

.morning-planning > div:not(:first-child):hover, .afternoon-planning > div:not(:first-child):hover {
  z-index: 9;
}

.morning-planning > div:first-child, .afternoon-planning > div:first-child {
  background-color: rgb(250, 251, 252);
  flex: 0 0 30px;
  width: 30px;
  color: rgb(150, 160, 170);
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0;
}

.morning-planning > div:first-child div, .afternoon-planning > div:first-child div {
  transform: rotate(-90deg);
}

#planning-content .technician-planning-container .morning-planning > div:not(:first-child),
#planning-content .technician-planning-container .afternoon-planning > div {
  border-bottom: 1px solid rgb(220, 225, 230);
}

.morning-planning > div:not(:first-child), .afternoon-planning > div:not(:first-child) {
  flex-direction: column;
  justify-content: flex-start;
}

.morning-planning > div, .afternoon-planning > div {
  border-right: 1px solid rgb(220, 225, 230);
}

#planning-header .current-date {
  background: linear-gradient(90deg, #ff6b00, #ff9100);
  color: white;
}

#planning-content .current-date {
  background: linear-gradient(90deg, #ff6b0011, #ff910011);
}

.multitask > div {
  display: flex;
  flex-direction: column;
}

.multitask > div:not(:last-child) {
  margin-bottom: 15px;
}

#next-tasks {
  height: calc(100vh - 354px);
  margin-left: -10px;
  padding-top: 2px;
  padding-left: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  scrollbar-width: thin;
}

#next-tasks::-webkit-scrollbar {
  width: 6px;
}

#next-tasks::-webkit-scrollbar-track {
  background: transparent;
}

#next-tasks::-webkit-scrollbar-thumb {
  background: rgb(100, 110, 120);
}

#next-tasks::-webkit-scrollbar-thumb:hover {
  background: rgb(80, 90, 100);
}

#task-container {
  width: 250px;
  height: 100%;
  padding-top: 30px;
}

#task-container .new-task {
  z-index: 11;
  padding-bottom: 20px;
}

.task-container-title {
  color: white;
  height: 35px;
  display: flex;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 15px;
  margin: 0 0 12px 15px;
  position: relative;
}

.task-container-title.orange {
  background: linear-gradient(90deg, #ff6b00, #ff9100);
}

.task-container-title.blue {
  background: linear-gradient(90deg, #1e73be, #00a1cf);
  margin-top: 25px;
}

.task-container-title:after {
  content: '';
  position: absolute;
  left: -15px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 35px solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 15px solid;
}

.task-container-title.orange:after {
  border-right-color: #ff6b00;
}

.task-container-title.blue:after {
  border-right-color: #1e73be;
}

.task {
  min-height: 60px;
  width: 220px;
  position: relative;
  background-color: white;
  box-shadow: 0 2px 5px -2px rgba(0, 20, 40, 0.4);
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 15px;
  padding-bottom: 12px;
  z-index: 10;
  flex-shrink: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#planning-container .task {
  width: 100%;
}

.task-topbar {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  height: 30px;
}

.task-is-closed-icon {
  color: #4FA601;
  font-size: 16px;
}

.task-right-container {
  display: flex;
  flex-direction: column;
}

.task-label-guarantee {
  color: #FFFFFF;
  background-color: #505A64;
  padding: 4px;
  border-radius: 5px;
  font-size: 8px;
  position: absolute;
  top: 25px;
}

.ui-draggable-handle {
  cursor: grab;
}

.ui-draggable-dragging {
  cursor: grabbing !important;
}

.ui-draggable-disabled {
  cursor: not-allowed !important;
}

.technician-planning .task-topbar > div, #next-tasks .task-topbar > div {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.technician-planning .task-topbar > div:first-child, #next-tasks .task-topbar > div:first-child {
  justify-content: flex-start;
}

.technician-planning .task-topbar > div:last-child, #next-tasks .task-topbar > div:last-child {
  justify-content: flex-end;
}

#new-task-container .task-topbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-draggable-area {
  height: 3px;
  width: 30px;
  border-radius: 20px;
  background: linear-gradient(90deg, #1e73be, #00a1cf);
}

.new-task .task-draggable-area {
  background: linear-gradient(90deg, #ff6b00, #ff9100);
}

.task-info-icon svg {
  width: 14px;
  height: 14px;
  fill: rgb(80, 90, 100);
  cursor: pointer;
}

.task-name {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 13px;
  color: #323246;
}

.task-name span {
  padding: 0 5px;
}

.task-name span:first-child {
  font-weight: 600;
  font-size: 14px;
}

.task-quotation-duration {
  font-size: 11px;
  color: #808080;
}

.task-affair-order-validated {
  background: linear-gradient(90deg, #4FA601, #7ad12c);
}

.droppable-half-day .task {
  margin-bottom: 0;
}

.droppable-half-day .task:not(:first-child) {
  margin-top: 15px;
}

.droppable-half-day.ui-droppable-hover {
  z-index: 2 !important;
}

.droppable-half-day:after {
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  width: 100%;
  height: 100%;
  background-color: #6ac0f211;
  opacity: 0;
  transition: 0.15s ease;
  border: 2px solid #6ac0f2;
}

.droppable-half-day.ui-droppable-hover:after {
  opacity: 1;
}

#planning-content .technician-planning-container:first-child .morning-planning .droppable-half-day:after {
  top: 0;
  height: calc(100% - 2px);
}

#planning-content .technician-planning-container .morning-planning .droppable-half-day:last-child:after,
#planning-content .technician-planning-container .afternoon-planning .droppable-half-day:last-child:after {
  width: calc(100% - 2px);
}

#planning-content .technician-planning-container:last-child .afternoon-planning .droppable-half-day:after {
  height: calc(100% - 4px);
}

.ui-draggable-disabled .task-draggable-area {
  cursor: not-allowed !important;
}

.new-task .inputeo {
  width: auto;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 0;
}

.new-task .inputeo:not(:last-child) {
  margin-bottom: 10px;
}

.new-task .inputeo select {
  font-size: 13px;
  padding: 7px 12px;
}

.new-task .inputeo select:required:invalid {
  color: rgb(80, 90, 100);
  font-weight: 400;
}

.new-task .inputeo option[value=""][disabled] {
  display: none;
}

.new-task .inputeo select option {
  color: rgb(50, 60, 70);
  font-weight: 600;
}

.force-task-z-index {
  z-index: 11 !important;
}

.ui-draggable-dragging {
  z-index: 12 !important;
}

.topbar-right-container #topbar-button-container .button:nth-child(1) {
  background: linear-gradient(90deg, #1E71BB, #187EC2);
  border-right: 1px solid #3790CA;
}

.topbar-right-container #topbar-button-container .button:nth-child(2) {
  background: linear-gradient(90deg, #187EC2, #0E8AC7);
  border-right: 1px solid #349BCF;
}

.topbar-right-container #topbar-button-container .button:nth-child(3) {
  background: linear-gradient(90deg, #0E8AC7, #0796CB);
}

.topbar-right-container #topbar-button-container .button {
  border-radius: 0px;
}

.topbar-right-container #topbar-button-container .button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.topbar-right-container #topbar-button-container .button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

#fake-task {
  display: none;
}

#technician-filter.inputeo {
  margin: 0px 40px;
  width: 400px;
  max-width: 400px;
  max-height: 40px;
}

#topbar-button-container {
  display: flex;
}

@media screen and (max-width: 1450px) {
  #technician-filter.inputeo {
    width: 200px;
    max-width: 200px;
  }
}

@media screen and (max-width: 1300px) {
  #technician-filter.inputeo {
    width: 160px;
    max-width: 160px;
  }
}

:deep(#technician-filter .multiselect__tags) {
  background: #FFFFFF;
  border-left: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
}

:deep(#technician-filter.inputeo input) {
  padding: 5px 15px 0px 15px;
  font-size: 12px;
  font-weight: normal;
}

.task-search {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-search > .inputeo {
  margin-bottom: 0;
}
</style>

<style>
.tippy-box {
  background-color: rgb(50, 60, 70);
}

.tippy-arrow {
  color: rgb(50, 60, 70);
}

.tippy-content {
  padding: 12px;
  color: white;
}

.delete-task-btn {
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  background: transparent;
  text-transform: uppercase;
  font-size: 12px;
  color: white;
  border-radius: 4px;
  padding: 6px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.15s ease;
}

.delete-task-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
}

.delete-task-btn svg {
  fill: white;
  width: 12px;
  height: 12px;
  margin-right: 7px;
  margin-bottom: 1px;
}

.tippy-info-line {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  margin-top: 8px;
}

.tippy-info-line > div:first-child {
  opacity: 0.7;
  text-align: left;
  margin-right: 20px;
}

.tippy-info-line > div:last-child {
  font-weight: 500;
}

.tippy-extend-task-buttons {
  display: flex;
  flex-wrap: wrap;
  max-width: 80px;
  justify-content: flex-end;
  margin-bottom: -6px;
}

.tippy-extend-task-buttons button {
  margin: 0 0 6px 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: transparent;
  font-size: 12px;
  color: white;
  border-radius: 4px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: 0.15s ease;
}

.tippy-extend-task-buttons button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
}
</style>

<template>
  <CustomTopbar>
    <template v-slot:custom-topbar>
      <BaseSelect
          id="technician-filter"
          v-model="technicianFilter"
          placeholder="Technicien"
          name="technician"
          api="account/search"
          :apiParams="{
                    role: accountRoleEnum.TECHNICIAN
                }"
          fieldValue="id"
          fieldLabel="name"
          :searchable="true"
          @onChange="onTechnicianSelectChange"
          :multiple="true"
          oneItemSelectedText="agent"
          multipleItemsSelectedText="agents"
          :minChars="1"
      />
      <div id="topbar-button-container">
        <BaseButton @click="previousWeek">
          <template v-slot:iconBefore>
            <font-awesome-icon :icon="['fas', 'chevron-left']"/>
          </template>
        </BaseButton>
        <BaseButton @click="resetWeek">
          <template v-slot:iconBefore>
            <font-awesome-icon :icon="['far', 'circle']"/>
          </template>
        </BaseButton>
        <BaseButton @click="nextWeek">
          <template v-slot:iconBefore>
            <font-awesome-icon :icon="['fas', 'chevron-right']"/>
          </template>
        </BaseButton>
      </div>
    </template>
  </CustomTopbar>
  <div id="planning-container" v-if="technicians.length > 0">
    <div id="planning-header">
      <div>Agent</div>
      <div v-for="(day, index) in technicians[0].days" :key="index"
           :class="day.date === dateOfTheDay ? 'current-date' : ''">
        {{ formateDate(day.date, 'EEEE dd/MM') }}
      </div>
    </div>
    <div id="planning-content" ref="planningContent">
      <div class="technician-planning-container" v-for="(technician) in technicians" :key="technician.id">
        <div class="technician-name">{{ technician.firstname }} {{ technician.lastname }}</div>
        <div class="technician-planning">
          <div class="morning-planning">
            <div>
              <div>Matin</div>
            </div>
            <div data-period="0" :data-day="formateDate(planningDay.date, 'yyyy-MM-dd')"
                 :data-technician-id="technician.id" class="droppable-half-day"
                 v-for="(planningDay, planningDayIndex) in technician.days"
                 :key="technician.id + '_0_' + planningDayIndex"
                 :class="planningDay.date === dateOfTheDay ? 'current-date' : ''">
              <div class="task" :data-id="task.id" :data-spindle-brand="task.spindle.brand"
                   :data-spindle-type="task.spindle.type" v-for="(task) in planningDay.tasks.morning" :key="task.id">
                <div class="task-topbar">
                  <div>
                    <div class="task-is-closed-icon" v-if="task.isClosed">
                      <font-awesome-icon :icon="['fas', 'check']"/>
                    </div>
                  </div>
                  <div>
                    <div class="task-draggable-area"
                         :class="task.affair.isOrderValidated ? 'task-affair-order-validated' : ''"></div>
                  </div>
                  <div class="task-right-container">
                    <div class="task-info-icon">
                      <font-awesome-icon :icon="['fas', 'info-circle']"/>
                    </div>
                    <div class="task-label-guarantee" v-if="task.affair.guarantee">GAR.</div>
                  </div>
                </div>
                <div class="task-name">
                  <span>{{ task.affair.number }}</span>
                  <span>{{ task.discipline.label }}</span>
                  <span class="task-quotation-duration">{{
                      task.quotationDuration != null ? `${task.quotationDuration}H prévue${task.quotationDuration > 1 ? 's' : ''}` : ''
                    }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="afternoon-planning">
            <div>
              <div>Après-midi</div>
            </div>
            <div data-period="1" :data-day="formateDate(planningDay.date, 'yyyy-MM-dd')"
                 :data-technician-id="technician.id" class="droppable-half-day"
                 v-for="(planningDay, planningDayIndex) in technician.days"
                 :key="technician.id + '_1_' + planningDayIndex"
                 :class="planningDay.date === dateOfTheDay ? 'current-date' : ''">
              <div class="task" :data-id="task.id" :data-spindle-brand="task.spindle.brand"
                   :data-spindle-type="task.spindle.type" v-for="(task) in planningDay.tasks.afternoon" :key="task.id">
                <div class="task-topbar">
                  <div>
                    <div class="task-is-closed-icon" v-if="task.isClosed">
                      <font-awesome-icon :icon="['fas', 'check']"/>
                    </div>
                  </div>
                  <div>
                    <div class="task-draggable-area"
                         :class="task.affair.isOrderValidated ? 'task-affair-order-validated' : ''"></div>
                  </div>
                  <div class="task-right-container">
                    <div class="task-info-icon">
                      <font-awesome-icon :icon="['fas', 'info-circle']"/>
                    </div>
                    <div class="task-label-guarantee" v-if="task.affair.guarantee">GAR.</div>
                  </div>
                </div>
                <div class="task-name">
                  <span>{{ task.affair.number }}</span>
                  <span>{{ task.discipline.label }}</span>
                  <span class="task-quotation-duration">{{
                      task.quotationDuration != null ? `${task.quotationDuration}H prévue${task.quotationDuration > 1 ? 's' : ''}` : ''
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="task-container" v-if="technicians.length > 0">
    <div class="task-container-title orange">
      Nouvelle tâche
    </div>
    <div id="new-task-container">
      <div class="task new-task"
           @dblclick="onTaskDoubleClick($event, false, newTaskAffair?.label, newTaskDiscipline?.label, newTaskAffair?.value, newTaskDiscipline?.value)">
        <div class="task-topbar">
          <div class="task-draggable-area"></div>
        </div>
        <BaseSelect
            v-model="newTaskAffair"
            placeholder="Affaire"
            name="affair"
            api="affair/search"
            fieldValue="id"
            fieldLabel="name"
            @onChange="onNewTaskChange"
            :searchable="true"
            :minChars="3"
            :maxHeight="300"
        />
        <BaseSelect
            v-model="newTaskDiscipline"
            placeholder="Discipline"
            name="discipline"
            api="discipline/search"
            fieldValue="id"
            fieldLabel="label"
            @onChange="onNewTaskChange"
            :searchable="true"
            :minChars="3"
            :maxHeight="300"
        />
      </div>
    </div>
    <div class="task-container-title blue">
      Prochaines tâches
    </div>

    <div id="next-tasks">
      <div class="task task-search">
        <BaseInput
            v-model="searchTask"
            placeholder="Tâche"
            name="task"
            fieldValue="id"
            fieldLabel="name"
            @keyup="filterTask"
            :minChars="2"
        />
      </div>

      <div
          @dblclick="onTaskDoubleClick($event, true, nextTask.affair.label, nextTask.discipline.label, nextTask.affair.id, nextTask.discipline.id)"
          class="task"
          :data-affair-id="nextTask.affair.id"
          :data-discipline-id="nextTask.discipline.id"
          v-for="(nextTask, nextTaskIndex) in nextTasks"
          :key="nextTask.affair.id + '_' + nextTask.discipline.id + '_' + nextTaskIndex"
      >
        <div class="task-topbar">
          <div>
            <div class="task-label-guarantee" v-if="nextTask.affair.guarantee">GAR.</div>
          </div>
          <div>
            <div class="task-draggable-area"
                 :class="nextTask.affair.isOrderValidated ? 'task-affair-order-validated' : ''"></div>
          </div>
          <div></div>
        </div>
        <div class="task-name">
          <span>{{ nextTask.affair.label }}</span>
          <span>{{ nextTask.discipline.label }}</span>
        </div>
      </div>
    </div>
  </div>
  <div id="fake-task" class="task">
    <div class="task-topbar">
      <div></div>
      <div>
        <div class="task-draggable-area"></div>
      </div>
      <div>
        <div class="task-info-icon">
          <font-awesome-icon :icon="['fas', 'info-circle']"/>
        </div>
      </div>
    </div>
    <div class="task-name">
      <span id="fake-task-affair-label"></span>
      <span id="fake-task-discipline-label"></span>
    </div>
  </div>
  <Modal v-show="displayPlaceTaskOnPlanningModal" :hide-icon="true">
    <template v-slot:modalIcon>
      <font-awesome-icon :icon="['fas', 'planning']"/>
    </template>
    <template v-slot:modalTitle>
      <div v-if="taskPlacementInfos.mode === 'manual'">NOUVELLE TÂCHE</div>
      <div v-else>{{ currentTaskAffairLabel }} - {{ currentDisciplineLabel }}</div>
    </template>
    <template v-slot:modalContent>
      <form>
        <div v-if="taskPlacementInfos.mode === 'manual'">
          <BaseSelect
              v-model="taskPlacementInfos.currentTaskAffair"
              placeholder="Affaire"
              name="affair"
              api="affair/search"
              fieldValue="id"
              fieldLabel="name"
              @onChange="onNewTaskChange"
              :searchable="true"
              :minChars="2"
          />
          <BaseSelect
              v-model="taskPlacementInfos.currentTaskDiscipline"
              placeholder="Discipline"
              name="discipline"
              api="discipline/search"
              fieldValue="id"
              fieldLabel="label"
              @onChange="onNewTaskChange"
              :searchable="true"
              :minChars="1"
          />
        </div>
        <div>
          <BaseDatepicker
              label="Date de la tâche"
              name="taskDate"
              v-model="taskPlacementInfos.date"
              :error="formErrorsModalPlaceTaskOnPlanningModal?.taskDate?.error?.message"
              @onChange="onFormInputChange"
              :displayError="displayErrorModalPlaceTaskOnPlanningModal"
          ></BaseDatepicker>
        </div>
        <div>
          <BaseSelect
              label="Période de la journée"
              name="period"
              :required="true"
              :default-options="period"
              v-model="taskPlacementInfos.period"
              :error="formErrorsModalPlaceTaskOnPlanningModal?.period?.error?.message"
              @onChange="onFormInputChange"
              :displayError="displayErrorModalPlaceTaskOnPlanningModal"
          ></BaseSelect>
        </div>
        <div>
          <BaseSelect
              label="Agent concerné"
              name="concernedAgent"
              api="account/search"
              :apiParams="{
                                role: accountRoleEnum.TECHNICIAN
                            }"
              :minChars="2"
              fieldValue="id"
              fieldLabel="name"
              :searchable=true
              :required="true"
              v-model="taskPlacementInfos.technician"
              :error="formErrorsModalPlaceTaskOnPlanningModal?.concernedAgent?.error?.message"
              @onChange="onFormInputChange"
              :displayError="displayErrorModalPlaceTaskOnPlanningModal"
          ></BaseSelect>
        </div>
        <div>
          <RadioButtonContainer label="Décaler les futures tâches ?">
            <BaseRadioButton v-model="taskPlacementInfos.moveNextTasks" label="Oui" name="moveNextTasks"
                             value="true"></BaseRadioButton>
            <BaseRadioButton v-model="taskPlacementInfos.moveNextTasks" label="Non" name="moveNextTasks"
                             value="false"></BaseRadioButton>
          </RadioButtonContainer>
        </div>
      </form>
    </template>
    <template v-slot:modalButtons>
      <BaseButton class="white-button" @click="closePlaceTaskOnPlanningModal" buttonText="Fermer">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'times']"/>
        </template>
      </BaseButton>
      <BaseButton class="orange-button" @click="placeTaskOnPlanning" buttonText="Valider">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'check']"/>
        </template>
      </BaseButton>
    </template>
  </Modal>
  <Dialog ref="dialog"/>
</template>

<script>
import {h} from "vue";
import axios from 'axios';
import {format, addDays, parse} from "date-fns";
import {fr} from 'date-fns/locale'
import BaseButton from '../../components/Base/BaseButton.vue';
import BaseSelect from '../../components/Base/BaseSelect.vue';
import CustomTopbar from '../../components/Topbar/CustomTopbar.vue';
import Dialog from '../../components/App/Dialog'
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import {initializeDragAndDropMobile} from '../../assets/js/lib/jquery-ui-touch-punch';
import anime from 'animejs';
import {useTippy} from 'vue-tippy';
import accountRoleEnum from "../../enums/accountRoleEnum";
import changeTypeEnum from '../../enums/changeTypeEnum';
import Modal from "@/components/App/Modal";
import BaseDatepicker from "@/components/Base/BaseDatepicker";
import BaseRadioButton from "@/components/Base/BaseRadioButton";
import RadioButtonContainer from "@/components/App/RadioButtonContainer";
import BaseInput from "@/components/Base/BaseInput.vue";

let context = null;

export default {
  name: 'PlanningTasks',
  components: {
    BaseInput,
    BaseRadioButton,
    BaseDatepicker,
    Modal,
    BaseButton,
    BaseSelect,
    CustomTopbar,
    Dialog,
    RadioButtonContainer
  },
  data() {
    return {
      affairs: [
        {label: 'Client', value: 'customer'},
        {label: 'Technicien', value: 'technician'},
        {label: 'Resp. Administratif ', value: 'technicianManager'},
        {label: 'Resp. Atelier', value: 'workshopManager'},
        {label: 'Administrateur', value: 'admin'},
      ],
      disciplines: [
        {label: 'Client', value: 'customer'},
        {label: 'Technicien', value: 'technician'},
        {label: 'Resp. Administratif ', value: 'technicianManager'},
        {label: 'Resp. Atelier', value: 'workshopManager'},
        {label: 'Administrateur', value: 'admin'},
      ],
      technicians: [],
      nextTasks: [],
      nextTasksForFiltering: [],
      accountRoleEnum,
      technicianFilter: [],
      dateOfTheDay: format(new Date(), 'yyyy-MM-dd'),
      currentDate: new Date(),
      newTaskAffair: null,
      newTaskDiscipline: null,
      tippy: {
        currentTaskId: null,
        currentSpindleBrand: null,
        currentSpindleType: null,
        x: 0,
        y: 0
      },
      displayPlaceTaskOnPlanningModal: false,
      currentTaskAffairLabel: '',
      currentDisciplineLabel: '',
      period: [
        {label: 'Matin', value: 0},
        {label: 'Après-midi', value: 1},
      ],
      taskPlacementInfos: {
        currentTaskAffair: null,
        currentTaskDiscipline: null,
        date: null,
        period: null,
        technician: null,
        moveNextTasks: false,
        autoTask: null,
        currentTaskType: null,
        mode: 'auto'
      },
      formErrorsModalPlaceTaskOnPlanningModal: {},
      displayErrorModalPlaceTaskOnPlanningModal: false,
      searchTask: ""
    }
  },
  mounted() {
    context = this;
    this.loadPlanningAndNextTasks();
    initializeDragAndDropMobile($);
  },
  methods: {
    formateDate(date, strFormat) {
      return format(new Date(date), strFormat, {locale: fr});
    },
    loadPlanningAndNextTasks(loadNextTasks = true) {
      return new Promise((resolve, reject) => {
        let promises = [this.loadPlanning()];
        if (loadNextTasks) promises.push(this.loadNextTasks());

        Promise.all(promises)
            .then(() => {
              this.handleDragAndDrop();
              resolve();
            })
            .catch(reject);
      });
    },
    loadPlanning(callback) {
      let technicians = this.technicianFilter.map((technician) => technician.value).join(',').trim();
      return new Promise((resolve, reject) => {
        axios
            .get(`planning/list?startedDate=${format(this.currentDate, 'yyyy-MM-dd')}${technicians.length > 0 ? `&technicianId=${technicians}` : ''}`, {
              showPreloader: false,
              toastError: true,
            })
            .then((result) => {
              if (typeof callback === 'function') callback();
              // Comme la variable "technicians" peut-être en désaccord avec la vue (qui est draggable), on force son rendu de cette façon

              const scroll = (this.$refs.planningContent?.scrollTop || -1);
              this.technicians = [];
              this.$nextTick(() => {
                this.technicians = result.data;
                this.$nextTick(() => {
                  if(scroll !== -1){
                    (this.$refs.planningContent) && (this.$refs.planningContent.scrollTop = scroll);
                  }
                });

                resolve();
              });
            })
            .catch(reject);
      })
    },
    previousWeek() {
      this.currentDate = addDays(this.currentDate, -7);
      this.loadPlanningAndNextTasks(false);
    },
    nextWeek() {
      this.currentDate = addDays(this.currentDate, 7);
      this.loadPlanningAndNextTasks(false);
    },
    resetWeek() {
      this.currentDate = new Date();
      this.loadPlanningAndNextTasks(false);
    },
    onNewTaskChange(input) {
      // Si on change l'affaire, on réinitialise la tâche
      if (input.name === 'affair' && input.changeType === changeTypeEnum.USER) {
        this.newTaskDiscipline = null;
      }

      if (this.newTaskAffair !== null && this.newTaskDiscipline !== null) {
        // On active le drag pour la nouvelle tâche
        $('.task.new-task.ui-draggable').draggable('enable');
        $('#fake-task-affair-label').html(this.newTaskAffair.label.substr(0, this.newTaskAffair.label.indexOf('-')));
        $('#fake-task-discipline-label').html(this.newTaskDiscipline.label);
      } else {
        // On désactive le drag pour la nouvelle tâche
        $('#fake-task-affair-label').html('');
        $('#fake-task-discipline-label').html('');
      }
    },
    loadNextTasks() {
      return new Promise((resolve, reject) => {
        axios
            .get('planning/nextTasks', {
              toastError: true,
              showPreloader: false
            })
            .then((result) => {
              this.nextTasks = [];
              this.$nextTick(() => {
                this.nextTasks = result.data;
                this.nextTasksForFiltering = result.data;
                this.filterTask();
                resolve();

              });
            })
            .catch(reject);
      });
    },
    moveTaskWithConfirmation(instance, taskId, days) {
      instance.hide();

      this.$refs.dialog.show({
        type: 'confirm',
        title: 'Confirmation',
        message: `Souhaitez-vous également décaler les futures tâches ?`,
        okButton: 'Oui',
        cancelButton: 'Non'
      }).then(() => {
        this.moveTask(taskId, days, true);
      })
          .catch(() => {
            this.moveTask(taskId, days, false);
          });
    },
    moveTask(taskId, days, moveNextTasks) {
      axios
          .post('planning/extendTask', {
            planningTaskId: taskId,
            duration: days,
            moveNextTasks: moveNextTasks
          }, {
            toastError: true,
            showPreloader: true
          })
          .then(() => {
            this.loadPlanningAndNextTasks(false);
          })
          .catch(() => {
          });
    },
    deleteTask(instance, taskId) {
      instance.hide();

      axios
          .put('planning/changeDeletedPlanningTask/' + taskId, {
            deleted: true
          }, {
            toastSuccessMessage: `Modification effectuée`,
            toastError: true,
            showPreloader: true
          })
          .then(() => {
            this.loadPlanningAndNextTasks();
          })
          .catch(() => {
          });
    },
    handleDragAndDrop() {
      this.addDroppableContainers();
      this.addDraggableTasks();
      this.addTooltip();
    },
    createPlanningTaskWithConfirmation(affairId, disciplineId, technicianId, date, period, autoTask, callback) {
      // Si la tâche provient de "nouvelle tâche", on propose le décalage
      if (!autoTask) {
        this.$refs.dialog.show({
          type: 'confirm',
          title: 'Confirmation',
          message: `Souhaitez-vous également décaler les futures tâches ?`,
          okButton: 'Oui',
          cancelButton: 'Non'
        }).then(() => {
          this.createPlanningTask(affairId, disciplineId, technicianId, date, period, autoTask, callback, true);
        })
            .catch(() => {
              this.createPlanningTask(affairId, disciplineId, technicianId, date, period, autoTask, callback, false);
            });
      } else { // Sinon, on ne décale pas
        this.createPlanningTask(affairId, disciplineId, technicianId, date, period, autoTask, callback, false);
      }
    },
    createPlanningTask(affairId, disciplineId, technicianId, date, period, autoTask, callback, moveNextTasks) {
      axios
          .post(`planning/create`, {
            affairId: affairId,
            disciplineId: disciplineId,
            accountId: technicianId,
            date: date,
            period: period,
            autoTask: autoTask,
            moveNextTasks: moveNextTasks
          }, {
            showPreloader: false,
            toastError: true
          })
          .then(() => {
            this.loadPlanningAndNextTasks()
                .then(() => {
                  if (typeof callback === 'function') callback();
                })
                .catch(() => {
                });
          })
          .catch(() => {
            this.loadPlanningAndNextTasks()
                .then(() => {
                  if (typeof callback === 'function') callback();
                })
                .catch(() => {
                });
          });
    },
    updatePlanningTask(taskId, technicianId, date, period) {
      axios
          .put(`planning/update`, {
            planningTaskId: taskId,
            accountId: technicianId,
            date: date,
            period: period
          }, {
            showPreloader: false,
            toastError: true
          })
          .then(() => {
            this.loadPlanningAndNextTasks();
          })
          .catch(() => {
            this.loadPlanningAndNextTasks();
          });
    },
    onTechnicianSelectChange(event) {
      if (event.changeType !== changeTypeEnum.INIT) this.loadPlanningAndNextTasks();
    },
    addTooltip() {
      let self = this;

      this.$nextTick(() => {
        let triggerTargets = [];
        $('.task-info-icon').each(function () {
          triggerTargets.push(this);
        })

        if (triggerTargets.length > 0) {
          const instance = useTippy(() => document.body, {
            content: {
              render: () => h('div', [
                h('button', {
                  class: 'delete-task-btn',
                  onClick: $event => {
                    self.deleteTask(instance, self.tippy.currentTaskId);
                  }
                }, [
                  h('div', 'Supprimer')
                ]),
                h('div', {
                  class: 'tippy-info-line'
                }, [
                  h('div', 'Marque'),
                  h('div', self.tippy.currentSpindleBrand),
                ]),
                h('div', {
                  class: 'tippy-info-line'
                }, [
                  h('div', 'Type'),
                  h('div', self.tippy.currentSpindleType),
                ]),
                h('div', {
                  class: 'tippy-info-line'
                }, [
                  h('div', [
                    h('span', 'Prolonger'),
                    h('br'),
                    h('span', '(en demi-j.)'),
                  ]),
                  h('div', {
                    class: 'tippy-extend-task-buttons'
                  }, [
                    h('button', {
                      onClick: $event => self.moveTaskWithConfirmation(instance, self.tippy.currentTaskId, 1)
                    }, '1'),
                    h('button', {
                      onClick: $event => self.moveTaskWithConfirmation(instance, self.tippy.currentTaskId, 2)
                    }, '2'),
                    h('button', {
                      onClick: $event => self.moveTaskWithConfirmation(instance, self.tippy.currentTaskId, 3)
                    }, '3'),
                    h('button', {
                      onClick: $event => self.moveTaskWithConfirmation(instance, self.tippy.currentTaskId, 4)
                    }, '4'),
                    h('button', {
                      onClick: $event => self.moveTaskWithConfirmation(instance, self.tippy.currentTaskId, 5)
                    }, '5'),
                    h('button', {
                      onClick: $event => self.moveTaskWithConfirmation(instance, self.tippy.currentTaskId, 6)
                    }, '6')
                  ])
                ]),
              ])
            },
            appendTo: () => document.body,
            triggerTarget: triggerTargets,
            placement: "right",
            hideOnClick: true,
            interactive: true,
            onTrigger(instance, event) {
              var path = event.path || (event.composedPath && event.composedPath());
              self.tippy.currentTaskId = path[3].dataset.id;
              self.tippy.currentSpindleBrand = path[3].dataset.spindleBrand;
              self.tippy.currentSpindleType = path[3].dataset.spindleType;
              self.tippy.x = (event.clientX - event.offsetX) + 10;
              self.tippy.y = (event.clientY - event.offsetY) + 65;
            },
            getReferenceClientRect: function () {
              return {
                width: 0,
                height: 0,
                top: self.tippy.y,
                left: self.tippy.x
              };
            }
          });
        }
      });
    },
    addDroppableContainers() {
      this.$nextTick(() => {
        $('.droppable-half-day:not(.ui-droppable)').droppable({
          drop: function (event, ui, position) {
            let task = ui.draggable[0];
            let taskId = task.dataset.id;
            let newTask = $(task).hasClass('new-task');

            let period = this.dataset.period;
            let date = this.dataset.day;
            let technicianId = this.dataset.technicianId;
            let affairId = newTask ? context.newTaskAffair.value : task.dataset.affairId;
            let disciplineId = newTask ? context.newTaskDiscipline.value : task.dataset.disciplineId;

            if (newTask) { // Si c'est une nouvelle tâche
              context.animeTaskDrag(event, ui, ui.offset, $('#fake-task'), true, () => {
                context.createPlanningTaskWithConfirmation(affairId, disciplineId, technicianId, date, period, false, () => {
                  context.newTaskAffair = null;
                  context.newTaskDiscipline = null;
                });
              });
            } else { // Si c'est une tâche déjà existante ou provenant de la liste des prochaines tâches
              context.animeTaskDrag(event, ui, ui.offset, task, false, () => {
                if (taskId == null) { // Si on créé une nouvelle tâche
                  context.createPlanningTaskWithConfirmation(affairId, disciplineId, technicianId, date, period, true, () => {
                    $(task).remove();
                  });
                } else { // Si on modifie une tâche déjà existante
                  context.updatePlanningTask(taskId, technicianId, date, period);
                }
              });
            }
          }
        });
      });
    },
    addDraggableTasks() {
      this.$nextTick(() => {
        $('.task:not(.ui-draggable)').draggable({
          handle: '.task-topbar',
          helper: 'clone',
          start: function (event, ui) {
            const outerWidth = $(this).outerWidth();

            $(this).css({
              'opacity': 0,
              'width': outerWidth + 'px'
            });
            ui.helper.css('width', outerWidth + 'px');
            ui.helper.parent().addClass('force-task-z-index');
          },
          stop: function () {
            $('.force-task-z-index').removeClass('force-task-z-index');
            $(this).css('opacity', 1);
          },
          revert: function () {
            $('.force-task-z-index').removeClass('force-task-z-index');
            return "invalid";
          },
          revertDuration: 200,
        });

        $('.task:not(.ui-draggable)').draggable('enable');

        if (this.newTaskAffair === null && this.newTaskDiscipline === null) {
          // On désactive le drag pour la nouvelle tâche
          $('.task.new-task.ui-draggable').draggable('disable');
        }
      });
    },
    refreshDragTasks() {
      $('.task').draggable({
        handle: '.task-topbar',
        helper: 'clone',
        start: function (event, ui) {
          const outerWidth = $(this).outerWidth();

          $(this).css({
            'opacity': 0,
            'width': outerWidth + 'px'
          });
          ui.helper.css('width', outerWidth + 'px');
          ui.helper.parent().addClass('force-task-z-index');
        },
        stop: function () {
          $('.force-task-z-index').removeClass('force-task-z-index');
          $(this).css('opacity', 1);
        },
        revert: function () {
          $('.force-task-z-index').removeClass('force-task-z-index');
          return "invalid";
        },
        revertDuration: 200,
      });

      $('.task').draggable('enable');
    },
    animeTaskDrag(event, ui, oldPosition, taskAnimated, makeCopy, callback) {
      let task = ui.draggable[0];
      let helper = ui.helper;
      let oldWidth = $(task).outerWidth();
      let newWidth = $(event.target).innerWidth() - 30;

      if (makeCopy) {
        taskAnimated = taskAnimated.clone().attr('id', 'fake-task-copy');
      }

      // On ajoute la tâche au planning et on supprime le "helper" (fausse tâche créée lors du drag)
      $(taskAnimated).appendTo($(event.target));
      helper.remove();

      // On réaffiche la vraie tâche (on voyait le helper pendant le drag)
      $(taskAnimated).css({
        'left': 0,
        'top': 0,
        'opacity': 1,
        'display': 'block'
      });

      // On récupère la nouvelle position de la tâche dans le planning pour pouvoir animer le déplacement de la tâche au moment où on drop
      let newPosition = this.getCoords($(taskAnimated).get(0));
      $(taskAnimated).css('width', newWidth + 'px');

      anime({
        targets: $(taskAnimated).get(0),
        duration: 200,
        easing: 'easeOutSine',
        width: [(oldWidth > newWidth ? newWidth : oldWidth) + 'px', newWidth + 'px'],
        top: [(oldPosition.top - newPosition.top) + 'px', '0px'],
        left: [(oldPosition.left - newPosition.left) + 'px', '0px'],
        complete: function () {
          $(taskAnimated).css('width', '100%');

          if (typeof callback === 'function') callback();
        }
      });
    },
    getCoords(elem) {
      const box = elem.getBoundingClientRect();

      const body = document.body;
      const docEl = document.documentElement;

      const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      const clientTop = docEl.clientTop || body.clientTop || 0;
      const clientLeft = docEl.clientLeft || body.clientLeft || 0;

      const top = box.top + scrollTop - clientTop;
      const left = box.left + scrollLeft - clientLeft;

      return {top: Math.round(top), left: Math.round(left)};
    },
    onTaskDoubleClick(event, autoTask, newTaskAffairLabel, newTaskDisciplineLabel, newTaskAffairId, newTaskDisciplineId) {
      if (!autoTask) {
        this.taskPlacementInfos.mode = 'manual'
      }

      this.currentTaskAffairLabel = newTaskAffairLabel
      this.currentDisciplineLabel = newTaskDisciplineLabel

      this.taskPlacementInfos.currentTaskAffair = {id: newTaskAffairId, label: newTaskAffairLabel}
      this.taskPlacementInfos.currentTaskDiscipline = {id: newTaskDisciplineId, label: newTaskDisciplineLabel}

      this.taskPlacementInfos.currentTaskType = autoTask
      this.displayPlaceTaskOnPlanningModal = true
    },
    closePlaceTaskOnPlanningModal() {
      this.displayPlaceTaskOnPlanningModal = false
      this.resetManualAddTask()
    },
    onFormInputChange(input) {
      if (input.error?.message !== null) {
        this.formErrorsModalPlaceTaskOnPlanningModal[input.name] = input;
      } else {
        delete this.formErrorsModalPlaceTaskOnPlanningModal[input.name];
      }
    },
    checkForm() {
      this.displayErrorModalPlaceTaskOnPlanningModal = true;

      return Object.keys(this.formErrorsModalPlaceTaskOnPlanningModal).length === 0;
    },
    placeTaskOnPlanning() {
      if (!this.checkForm()) return;

      this.createPlanningTask(
          this.taskPlacementInfos.currentTaskAffair.id || this.taskPlacementInfos.currentTaskAffair.value,
          this.taskPlacementInfos.currentTaskDiscipline.id || this.taskPlacementInfos.currentTaskDiscipline.value,
          this.taskPlacementInfos.technician.value,
          format(parse(this.taskPlacementInfos.date, "dd/MM/yyyy", new Date()), "yyyy-MM-dd"),
          this.taskPlacementInfos.period.value,
          this.taskPlacementInfos.currentTaskType,
          this.taskPlacementInfos.moveNextTasks
      )

      if (this.taskPlacementInfos.mode === 'manual') {
        this.newTaskAffair = null
        this.newTaskDiscipline = null
      }

      this.closePlaceTaskOnPlanningModal()
      this.resetManualAddTask()
    },
    resetManualAddTask() {
      this.displayErrorModalPlaceTaskOnPlanningModal = false
      this.taskPlacementInfos = {
        currentTaskAffair: null,
        currentTaskDiscipline: null,
        date: null,
        period: null,
        technician: null,
        moveNextTasks: false,
        autoTask: null,
        currentTaskType: null,
        mode: 'auto'
      }
    },
    filterTask(){
      let tasks = this.nextTasksForFiltering;
      let filteredTasks;

      if (!this.searchTask)
        filteredTasks = tasks;
      else
        filteredTasks = tasks.filter((task) => ((task.affair.label.includes(this.searchTask) || task.affair.label.includes(this.searchTask.toUpperCase()) || task.affair.label.includes(this.searchTask.toLocaleUpperCase()))
            || (task.discipline.label.includes(this.searchTask) || task.discipline.label.includes(this.searchTask.toUpperCase()) || task.discipline.label.includes(this.searchTask.toLocaleUpperCase()))));

      this.nextTasks = filteredTasks;

      this.$nextTick(() => this.refreshDragTasks());
    }
  }
}
</script>
